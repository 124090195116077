@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Nunito:wght@400;500;600;700&display=swap');

html, body {
    margin: 0;
    height: 100%;
}

body {
    background-color: #0d2538;
    color: rgb(251, 251, 254);
    font-family: Nunito, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}
