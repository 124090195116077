#terminal {
}
#terminal__bar {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: linear-gradient(#504b45 0%,#3c3b37 100%);
}
#bar__buttons {
    display: flex;
    align-items: center;
}
.bar__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 5px;
    font-size: 8px;
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: 100%;
    background: linear-gradient(#7d7871 0%, #595953 100%);
    text-shadow: 0px 1px 0px rgba(255,255,255,0.2);
    box-shadow: 0px 0px 1px 0px #41403A, 0px 1px 1px 0px #474642;
}
.bar__button:hover {
    cursor: pointer;
}
.bar__button:focus {
    outline: none;
}
.margin-top {
    margin-top: 10px;
}
#bar__button--exit {
    background: linear-gradient(#f37458 0%, #de4c12 100%);
    background-clip: padding-box;
}
#bar__user {
    color: #d5d0ce;
    margin-left: 6px;
    font-size: 14px;
    line-height: 15px;
}
#terminal__body {
    background: rgba(56, 4, 40, 0.9);
    height: calc(100% - 30px);
    padding-top: 2px;
    margin-top: -1px;
}
#terminal__prompt {
    display: flex;
}
#terminal__prompt--two {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    gap: 5px 0;
    padding: 5px;
}
#terminal__prompt--two-bling {
    color: #dddddd;
    width: 50%;
}
#terminal__prompt--user,
#terminal__prompt--title {
    color: #7eda28;
}
#terminal__prompt--location {
    color: #4878c0;
}
#terminal__prompt--bling {
    color: #dddddd;
}
#terminal__prompt--cursor {
    display: block;
    height: 17px;
    width: 8px;
    margin-left: 9px;
    animation: blink 1200ms linear infinite;
}
@keyframes blink {
    0% {
        background: #ffffff;
    }
    49% {
        background: #ffffff;
    }
    60% {
        background: transparent;
    }
    99% {
        background: transparent;
    }  100% {
           background: #ffffff;
       }
}
@media (max-width: 600px) {
    #terminal {
        max-height: 90%;
    }
}
